import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql
} from "@apollo/client"
import { createUploadLink } from 'apollo-upload-client'

const { REACT_APP_GQL_URI } = process.env
//const REACT_APP_GQL_URI = "http://localhost:3333/graphql"

const client = new ApolloClient({
  link: createUploadLink({
    uri: REACT_APP_GQL_URI,
  }),
  cache: new InMemoryCache(),  
})

export default client
